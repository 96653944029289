import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d280bbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "contact-details"
}
const _hoisted_2 = { class: "contact-details__title h4" }
const _hoisted_3 = {
  key: 0,
  class: "contact-details__recaptcha-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Combobox = _resolveComponent("Combobox")!
  const _component_InputField = _resolveComponent("InputField")!
  const _component_Article = _resolveComponent("Article")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Recaptcha = _resolveComponent("Recaptcha")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_FeedbackDialog = _resolveComponent("FeedbackDialog")!

  return (_ctx.pageContent)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.pageContent.title), 1),
        _createVNode(_component_Combobox, {
          ref: "title-combobox",
          modelValue: _ctx.title,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
          "input-id": "select-title",
          options: ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr'],
          label: "Title",
          class: "mb-4 contact-details__title-select form-group__input-group"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_InputField, {
          id: "firstName",
          ref: "first-name-input",
          modelValue: _ctx.firstName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstName) = $event)),
          name: "firstName",
          label: "First name",
          "error-message": 
        _ctx.v$.firstName.$error ? _ctx.pageContent.firstNameInputFieldErrorMessage : null
      ,
          "has-error": _ctx.v$.firstName.$error,
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.v$.firstName.$touch()))
        }, null, 8, ["modelValue", "error-message", "has-error"]),
        _createVNode(_component_InputField, {
          id: "lastName",
          ref: "last-name-input",
          modelValue: _ctx.lastName,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastName) = $event)),
          name: "lastName",
          label: "Last name",
          "error-message": 
        _ctx.v$.lastName.$error ? _ctx.pageContent.lastNameInputFieldErrorMessage : null
      ,
          "has-error": _ctx.v$.lastName.$error,
          onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.v$.lastName.$touch()))
        }, null, 8, ["modelValue", "error-message", "has-error"]),
        _createVNode(_component_InputField, {
          id: "email",
          ref: "email-input",
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.email) = $event)),
          name: "email",
          label: "Email",
          "error-message": 
        _ctx.v$.email.$error ? _ctx.pageContent.emailInputFieldErrorMessage : null
      ,
          "has-error": _ctx.v$.email.$error,
          onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.v$.email.$touch()))
        }, null, 8, ["modelValue", "error-message", "has-error"]),
        _createVNode(_component_InputField, {
          id: "phone",
          ref: "phone-input",
          modelValue: _ctx.phone,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.phone) = $event)),
          name: "phone",
          label: "Phone",
          "error-message": 
        _ctx.v$.phone.$error ? _ctx.pageContent.phoneInputFieldErrorMessage : null
      ,
          "has-error": _ctx.v$.phone.$error,
          onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.v$.phone.$touch()))
        }, null, 8, ["modelValue", "error-message", "has-error"]),
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.dataConsent,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dataConsent) = $event)),
          class: "contact-details__checkbox",
          "input-id": "data-consent",
          "is-small": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Article, {
              "text-content": _ctx.pageContent.dataConsentCheckboxCopy,
              "no-margin": ""
            }, null, 8, ["text-content"])
          ], undefined, true),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.propertyConfirmation,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.propertyConfirmation) = $event)),
          class: "contact-details__checkbox",
          "input-id": "property-confirmation",
          "is-small": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Article, {
              "text-content": _ctx.pageContent.propertyConfirmationCheckboxCopy,
              "no-margin": ""
            }, null, 8, ["text-content"])
          ], undefined, true),
          _: 1
        }, 8, ["modelValue"]),
        (!_ctx.recaptchaDisabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Recaptcha, {
                ref: "recaptcha",
                sitekey: _ctx.siteKey,
                onSuccess: _ctx.recaptchaSuccess
              }, null, 8, ["sitekey", "onSuccess"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_AppButton, {
          class: "contact-details__next-button",
          "full-width": "",
          size: "large",
          disabled: 
        !_ctx.dataConsent ||
        !_ctx.propertyConfirmation ||
        !(_ctx.recaptchaSucceded || _ctx.recaptchaDisabled)
      ,
          loading: _ctx.loading,
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.pageContent.nextStepButtonCopy), 1)
          ], undefined, true),
          _: 1
        }, 8, ["disabled", "loading", "onClick"]),
        (_ctx.quoteError)
          ? (_openBlock(), _createBlock(_component_FeedbackDialog, {
              key: 1,
              class: "contact-details__feedback-dialog",
              message: _ctx.pageContent.submissionError,
              variant: "negative"
            }, null, 8, ["message"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}