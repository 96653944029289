<template>
  <div v-if="pageContent" class="enter-postcode">
    <h1 class="enter-postcode__title h4">
      {{ pageContent.title }}
    </h1>
    <p class="enter-postcode__subtitle">
      {{ pageContent.subtitle }}
    </p>
    <InputField
      v-model="postcode"
      label="Postcode"
      name="postcode"
      full-width
      :change="isValidPostcodeProvided"
    />
    <AppButton
      class="enter-postcode__next-button"
      :disabled="!isValidPostcodeProvided"
      full-width
      @click="submit"
    >
      <span>{{ pageContent.nextButtonCopy }}</span>
      <span class="icon icon-arrow-right"></span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapMutations } from "vuex"

import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin.js"
import story from "soenergy-cms-loader!?path=solar/enter-postcode"
import isValidUkPostcode from "@soenergy/frontend-library/src/helpers/isValidUkPostcode"
import serveUserBasedOnPostcode from "@/helpers/serveUserBasedOnPostcode"

export interface SolarSelectAddress {
  title: string
  subtitle: string
  nextButtonCopy: string
}

export default defineComponent({
  components: {
    AppButton,
    InputField,
  },
  mixins: [cmsPreviewMixin<SolarSelectAddress>({ story })],
  emits: ["go-forward", "postcode-is-valid"],
  data: () => ({
    postcode: "",
  }),
  computed: {
    isValidPostcodeProvided() {
      return isValidUkPostcode(this.postcode)
    },
  },
  methods: {
    ...mapMutations({
      setPostcode: "SET_POSTCODE",
      resetState: "RESET_STATE",
    }),
    submit() {
      this.resetState()
      this.setPostcode(this.postcode)
      serveUserBasedOnPostcode(this.postcode)
    },
  },
})
</script>

<style lang="scss" scoped>
.enter-postcode {
  &__title {
    margin-bottom: $space-10;
    text-transform: none;
  }

  &__subtitle {
    font-size: $font-size-4;
    margin-bottom: $space-10;
  }

  &__next-button {
    margin-top: $space-6;
  }
}
</style>
