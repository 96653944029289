import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57887dd3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "customer-login"
}
const _hoisted_2 = { class: "customer-login__title h4" }
const _hoisted_3 = { class: "customer-login__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputFieldVue = _resolveComponent("InputFieldVue")!
  const _component_Article = _resolveComponent("Article")!
  const _component_FeedbackDialog = _resolveComponent("FeedbackDialog")!
  const _component_AppButtonVue = _resolveComponent("AppButtonVue")!

  return (_ctx.pageContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.pageContent.title), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.pageContent.description), 1),
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
        }, [
          _createVNode(_component_InputFieldVue, {
            modelValue: _ctx.emailField.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailField.value) = $event)),
            "has-error": _ctx.v$.emailField.value.$fire || _ctx.submitStatus === 'ERROR',
            name: "email",
            label: "Email",
            autocomplete: "email",
            type: "email",
            "error-message": _ctx.getErrorMessage('emailField'),
            required: ""
          }, null, 8, ["modelValue", "has-error", "error-message"]),
          _createVNode(_component_InputFieldVue, {
            modelValue: _ctx.passwordField.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordField.value) = $event)),
            "has-error": _ctx.v$.passwordField.value.$fire || _ctx.submitStatus === 'ERROR',
            "error-message": _ctx.getErrorMessage('passwordField'),
            name: "password",
            label: "Password",
            type: "password",
            required: ""
          }, null, 8, ["modelValue", "has-error", "error-message"]),
          (_ctx.isAccountLocked)
            ? (_openBlock(), _createBlock(_component_FeedbackDialog, {
                key: 0,
                variant: "negative",
                class: "mb-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Article, {
                    ref: "activeAccountText",
                    "no-margin": true,
                    "text-content": _ctx.pageContent.lockedAccountText
                  }, null, 8, ["text-content"])
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_AppButtonVue, {
            disabled: !_ctx.emailField.value || !_ctx.passwordField.value,
            loading: _ctx.submitStatus === 'PENDING',
            class: "customer-login__next-button",
            "full-width": "",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.pageContent.nextStepButton), 1)
            ], undefined, true),
            _: 1
          }, 8, ["disabled", "loading"])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}