import { createApp, nextTick } from "vue"

import App from "./App.vue"
import router from "./router/index"
import store, { key as storeKey } from "./store"
import initErrorReporting from "./helpers/initErrorReporting"
import StoryblokHelperPlugin from "@soenergy/frontend-library/src/plugins/storyblokHelper"

import "@soenergy/frontend-library/src/stylesheets/vendors/bulma.scss"
import "@soenergy/frontend-library/src/stylesheets/base.scss"
import "@soenergy/frontend-library/src/stylesheets/components/_form.scss"
import "@soenergy/frontend-library/src/stylesheets/components/_list.scss"
import UserStatusService from "@soenergy/frontend-library/src/services/UserStatusService"

import * as CookieConsentPlugin from "@soenergy/frontend-library/src/services/CookieConsentPlugin"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import PersistentStoreService from "@soenergy/frontend-library/src/services/PersistentStoreService"
import AnalyticsPlugin, {
  AnalyticsService,
  MixpanelTracker,
} from "@soenergy/frontend-library/src/plugins/analytics"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"
import { runOnceCookieIsAllowed } from "@soenergy/frontend-library/src/services/CookiePreferences"

import { ObserveVisibility } from "vue-observe-visibility"
import VueCookies from "vue-cookies"
import { initDatadogPlugin } from "@soenergy/frontend-library/src/services/datadog"
import initDatadogIdentity from "@/helpers/initDatadogIdentity"
const app = createApp(App)
app.use(store, storeKey)
app.use(router)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(VueCookies)

initErrorReporting(app)
app.use(initDatadogPlugin())
app.use(StoryblokHelperPlugin)
app.use(AnalyticsPlugin)
app.directive("observe-visibility", ObserveVisibility)

if (process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true") {
  CookieConsentPlugin.init()
}
UserStatusService.init({ store })

AuthenticationService.init({
  store,
  onSessionExpired: () => {
    store.dispatch("logout")
  },
})
PersistentStoreService.init({
  store,
  reducer: (state) => ({
    quote: state.quote,
    userStatus: state.userStatus,
  }),
})
runOnceCookieIsAllowed("performance", () => {
  MixpanelService.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
    projectName: PROJECTS.Solar,
  })
  AnalyticsService.init({
    trackers: [MixpanelTracker],
    vueRouter: router,
    projectName: PROJECTS.Solar,
    nextTick: nextTick,
  })
  initDatadogIdentity()
})

app.mount("#app")
