<template>
  <div v-if="pageContent" class="select-address">
    <h1 class="select-address__title h4">
      {{ pageContent.title }} {{ postcode }}?
    </h1>
    <p class="select-address__description">
      {{ pageContent.subtitle }}
    </p>
    <AddressField
      v-model:address="selectedAddress"
      v-model:first-line-address="manualAddressLine1"
      v-model:second-line-address="manualAddressLine2"
      class="customer-address__address-field"
      input-id="select-supply-address"
      label="Supply address"
      placeholder="Select your supply address"
      :loading="loading"
      :addresses="electricityAddresses"
      :address-missing-value="addressMissingValue"
    />
    <FeedbackDialog
      v-if="showAddressFetchError"
      class="register__feedback-dialog"
      :message="pageContent.addressFetchError"
      variant="negative"
    />
    <AppButton
      class="select-address__next-button"
      :disabled="isAddressProvided"
      full-width
      @click="submit"
    >
      <span>{{ pageContent.nextButtonCopy }}</span>
      <span class="icon icon-arrow-right"></span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapMutations } from "vuex"

import SupplyAddressApiService from "@soenergy/frontend-library/src/services/api/SupplyAddressApiService"

import AddressField from "@soenergy/frontend-library/src/components/AddressField.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin.js"
import story from "soenergy-cms-loader!?path=solar/select-address"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

export interface SolarSelectAddress {
  title: string
  description: string
  nextButtonCopy: string
  subtitle: string
  addressFetchError: string
}

export default defineComponent({
  components: {
    AddressField,
    AppButton,
    FeedbackDialog,
  },
  mixins: [cmsPreviewMixin<SolarSelectAddress>({ story })],
  emits: ["go-forward"],
  data: () => ({
    loading: false,
    showAddressFetchError: false,
    isManualAddressInputOn: false,
    electricityAddresses: [],
    selectedAddress: "",
    manualAddressLine1: "",
    manualAddressLine2: "",
    addressMissingValue: "address_not_listed",
  }),
  computed: {
    postcode() {
      const postcode = this.$store.state.quote.postcode as string
      return postcode.toUpperCase()
    },
    manualAddressLine() {
      if (!this.manualAddressLine1) return null

      return this.manualAddressLine2
        ? `${this.manualAddressLine1}, ${this.manualAddressLine2}`
        : this.manualAddressLine1
    },
    isAddressProvided() {
      return (
        !this.selectedAddress ||
        (this.selectedAddress === this.addressMissingValue &&
          !this.manualAddressLine)
      )
    },
  },
  created() {
    this.getAddresses()
  },
  methods: {
    ...mapMutations({
      setAddress: "SET_ADDRESS",
    }),
    async getAddresses() {
      this.loading = true
      try {
        this.showAddressFetchError = false
        const list = await SupplyAddressApiService.getForPostcode(this.postcode)
        this.electricityAddresses = list.filter((address) => !!address.mpan)
      } catch (err) {
        ErrorReporter.report(err, { context: "solar getForPostcode failed" })
        this.showAddressFetchError = true
      } finally {
        this.loading = false
      }
    },
    submit() {
      const address =
        this.selectedAddress === this.addressMissingValue
          ? { address: this.manualAddressLine }
          : this.selectedAddress
      this.setAddress(address)
      this.$emit("go-forward")
    },
  },
})
</script>

<style lang="scss" scoped>
.select-address {
  &__title {
    margin-bottom: $space-10;
    text-transform: none;
  }

  &__description {
    font-size: $font-size-4;
    margin-bottom: $space-10;
  }

  &__next-button {
    margin-top: $space-6;
  }
}
</style>
