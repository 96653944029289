import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c545952"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quote-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepWizard = _resolveComponent("StepWizard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_StepWizard, {
      modelValue: _ctx.currentStepNumber,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentStepNumber) = $event)),
      class: _normalizeClass(["quote-container__steps section", _ctx.stepClasses]),
      steps: _ctx.steps,
      "hide-title": "",
      onReturned: _ctx.returnToStart,
      onCompleted: _ctx.goToSuccessPage
    }, null, 8, ["modelValue", "class", "steps", "onReturned", "onCompleted"])
  ]))
}