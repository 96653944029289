<template>
  <div v-if="pageContent" class="customer-login">
    <h1 class="customer-login__title h4">{{ pageContent.title }}</h1>
    <p class="customer-login__description">
      {{ pageContent.description }}
    </p>
    <form @submit.prevent="login">
      <InputFieldVue
        v-model="emailField.value"
        :has-error="v$.emailField.value.$fire || submitStatus === 'ERROR'"
        name="email"
        label="Email"
        autocomplete="email"
        type="email"
        :error-message="getErrorMessage('emailField')"
        required
      />
      <InputFieldVue
        v-model="passwordField.value"
        :has-error="v$.passwordField.value.$fire || submitStatus === 'ERROR'"
        :error-message="getErrorMessage('passwordField')"
        name="password"
        label="Password"
        type="password"
        required
      />
      <FeedbackDialog v-if="isAccountLocked" variant="negative" class="mb-2">
        <Article
          ref="activeAccountText"
          :no-margin="true"
          :text-content="pageContent.lockedAccountText"
        />
      </FeedbackDialog>
      <AppButtonVue
        :disabled="!emailField.value || !passwordField.value"
        :loading="submitStatus === 'PENDING'"
        class="customer-login__next-button"
        full-width
        type="submit"
      >
        {{ pageContent.nextStepButton }}
      </AppButtonVue>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import InputFieldVue from "@soenergy/frontend-library/src/components/InputField.vue"
import AppButtonVue from "@soenergy/frontend-library/src/components/AppButton.vue"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import { useVuelidate } from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import { mapActions } from "vuex"
import store from "@/store"
import UserStatusService from "@soenergy/frontend-library/src/services/UserStatusService"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/login"

interface SolarCustomerLogin {
  title: string
  description: string
  nextStepButton: string
  lockedAccountText: string
}

export default defineComponent({
  components: {
    InputFieldVue,
    AppButtonVue,
    FeedbackDialog,
    Article,
  },
  mixins: [cmsPreviewMixin<SolarCustomerLogin>({ story })],
  emits: ["go-forward"],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      emailField: {
        errorMessage: "",
        messages: {
          invalid: "Please enter a valid email address",
          empty: "Please enter an email address",
        },
        value: "",
      },
      passwordField: {
        errorMessage: "",
        messages: {
          invalid: "Please enter a valid password",
          empty: "Please enter an password",
        },
        value: "",
      },
      submitStatus: "",
      isAccountLocked: false,
    }
  },
  validations() {
    return {
      emailField: { value: { required, email } },
      passwordField: { value: { required } },
    }
  },
  methods: {
    ...mapActions({
      loginAction: "login",
    }),
    login() {
      if (!this.v$.$invalid) {
        this.submitStatus = "PENDING"
        store
          .dispatch("login", {
            email: this.emailField.value,
            password: this.passwordField.value,
          })
          .then(() => {
            UserStatusService.getUserStatusWithToken()
            this.$emit("go-forward")
          })
          .catch((err) => {
            this.submitStatus = "ERROR"
            this.emailField.errorMessage = ""
            this.passwordField.errorMessage = ""

            if (err.response && err.response.status == 401) {
              if (
                err.response.data &&
                err.response.data.error === "account locked"
              ) {
                this.isAccountLocked = true
              } else {
                this.passwordField.errorMessage =
                  "Email or password is incorrect. Please try again."
              }
            } else if (err.message == "Network Error") {
              this.passwordField.errorMessage =
                "Cannot reach the So Energy server"
            } else {
              this.passwordField.errorMessage = "Unknown error!"
              throw err
            }
          })
      }
    },
    getErrorMessage(field) {
      if (this[field]) {
        return this[field]["errorMessage"]
      }
      if (!this.v$[field].value.required && this.v$[field].value.$fire) {
        return this[field]["messages"]["empty"]
      }
      if (!this.v$[field].value.email && this.v$[field].value.$fire) {
        return this[field]["messages"]["invalid"]
      }
      return null
    },
  },
})
</script>

<style lang="scss" scoped>
.customer-login {
  &__title {
    margin-bottom: $space-10;
    text-transform: none;
  }

  &__description {
    font-size: $font-size-4;
    margin-bottom: $space-10;
  }

  &__next-button {
    margin-top: $space-6;
  }

  &__error {
    font-size: $font-size-3;
    margin-top: $space-6;
    color: $red-700;
  }
}
</style>
