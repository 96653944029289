<template>
  <section v-if="pageContent" class="flat-roof">
    <h1 class="flat-roof__title h4">{{ pageContent.title }}</h1>
    <RadioGroup v-model="isFlatRoof" :options="options" />
    <a class="flat-roof-info" @click="toggleFlatRoofInfoModal()">
      <img
        :src="require('@/assets/images/question-mark.svg')"
        class="flat-roof-info__icon"
      />
      <span class="flat-roof-info__text">
        {{ pageContent.flatRoofInfoTextCopy }}</span
      >
    </a>
    <AppButton
      :disabled="isFlatRoof === null"
      class="flat-roof__next-button"
      full-width
      @click="goNext"
    >
      <span>{{ pageContent.nextButton }}</span>
      <span class="flat-roof__icon icon icon-arrow-right" />
    </AppButton>
    <Modal
      :is-modal-open="showFlatRoofInfoModal"
      :is-full-screen="true"
      @close-modal="toggleFlatRoofInfoModal"
    >
      <template #body>
        <div>
          <h4 class="flat-roof__title">{{ pageContent.modalTitleCopy }}</h4>
          <p class="flat-roof__details">
            {{ pageContent.flatRoofDetailsCopy }}
          </p>
        </div>
      </template>
      <template #cancelButton>{{ pageContent.closeModalButtonCopy }}</template>
    </Modal>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import { RadioGroup } from "@soenergy/frontend-library/src/components/RadioButton"
import Modal from "@soenergy/frontend-library/src/components/Modal.vue"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/flat-roof-check"
import router from "@/router/index"
import { Page } from "@/router/types"

export interface SolarQuoteFlatRoofCheck {
  title: string
  flatRoofInfoTextCopy: string
  nextButton: string
  flatRoofDetailsCopy: string
  modalTitleCopy: string
  closeModalButtonCopy: string
}

export default defineComponent({
  components: {
    AppButton,
    RadioGroup,
    Modal,
  },
  mixins: [cmsPreviewMixin<SolarQuoteFlatRoofCheck>({ story })],
  emits: ["go-forward"],
  data() {
    return {
      showFlatRoofInfoModal: false,
      options: [
        {
          label: "Yes",
          value: true,
          inputId: "flat-roof-yes",
        },
        {
          label: "No",
          value: false,
          inputId: "flat-roof-no",
        },
      ],
    }
  },
  computed: {
    isFlatRoof: {
      get() {
        return this.$store.state.quote.isFlatRoof
      },
      set(value) {
        this.$store.commit("SET_FLAT_ROOF", value)
      },
    },
  },
  methods: {
    toggleFlatRoofInfoModal() {
      this.showFlatRoofInfoModal = !this.showFlatRoofInfoModal
    },
    goNext() {
      this.$emit("go-forward")
    },
  },
})
</script>

<style scoped lang="scss">
.flat-roof {
  &__title {
    text-transform: none;
  }

  &__next-button {
    margin-top: $space-8;

    &__icon {
      margin-left: $space-2;
    }
  }
}

.flat-roof {
  &-info {
    display: inline-flex;
    align-items: center;
    margin: $space-5 0;
    color: $night;
    text-decoration: none;
    cursor: pointer;
    font-size: $font-size-3;

    &__text {
      margin-left: $space-2;
    }
  }
}
</style>
