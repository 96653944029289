<template>
  <div class="quote-container">
    <StepWizard
      v-model="currentStepNumber"
      class="quote-container__steps section"
      :class="stepClasses"
      :steps="steps"
      hide-title
      @returned="returnToStart"
      @completed="goToSuccessPage"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import StepWizard from "@/components/StepWizard.vue"

import { Page } from "@/router/types"
import { mapState } from "vuex"
import { RootState } from "@/store/untested/types"

export default defineComponent({
  components: {
    StepWizard,
  },
  data: () => ({
    currentStepNumber: null,
    newBranding: process.env.VUE_APP_NEW_BRANDING === "true",
  }),
  computed: {
    ...mapState({
      selectedPackage: (state: RootState) => state.quote.solarPackage,
    }),
    stepClasses() {
      return {
        "full-width":
          this.steps[this.currentStepNumber ? this.currentStepNumber : 0]
            .fullWidth,
      }
    },
    steps() {
      return [
        {
          routeName: Page.EnterPostcode,
          skip: this.$store.getters.isLoggedIn || !this.newBranding,
        },
        {
          routeName: Page.SelectAddress,
          skip: this.$store.getters.isLoggedIn,
        },
        {
          routeName: Page.CustomerCheck,
          skip: this.$store.getters.isLoggedIn,
        },
        {
          routeName: Page.QuoteCustomerLogin,
          skip: !this.$store.state.quote.isExistingCustomer,
        },
        {
          routeName: Page.QuoteCustomerDetails,
          skip:
            !this.$store.state.quote.isExistingCustomer &&
            !this.$store.getters.isLoggedIn,
        },
        {
          routeName: Page.QuoteNewPostcode,
          skip: !this.$store.state.quote.overrideAddress,
        },
        {
          routeName: Page.QuoteNewAddress,
          skip: !this.$store.state.quote.overrideAddress,
        },
        { routeName: Page.FindYourRoof, fullWidth: true },
        { routeName: Page.ExtraInfo, fullWidth: true },
        { routeName: Page.FlatRoofCheck },
        {
          routeName: Page.QuoteSolarPackages,
          fullWidth: true,
        },
        {
          routeName: Page.QuoteSolarProductType,
          fullWidth: true,
          skip: this.selectedPackage === "battery_only",
        },
        {
          routeName: Page.QuoteElectricityUsage,
          skip:
            this.$store.state.quote.estimatedUsage.fromApi &&
            !this.$store.state.quote.overrideAddress,
        },
        {
          routeName: Page.QuoteElectricityUsageEstimate,
          skip:
            this.$store.state.quote.isUsageExact === true ||
            (this.$store.state.quote.estimatedUsage.fromApi &&
              !this.$store.state.quote.overrideAddress),
        },
        {
          routeName: Page.QuoteElectricityUnitRate,
          skip:
            this.$store.state.quote.unitRate.fromApi &&
            !this.$store.state.quote.overrideAddress,
        },
        {
          routeName: Page.WhenAtHome,
        },
        {
          routeName: Page.WhenGetSolar,
        },
        {
          routeName: Page.ConservationArea,
        },
        {
          routeName: Page.ContactDetails,
        },
      ]
    },
  },
  methods: {
    returnToStart() {
      if (this.newBranding) {
        window.location.assign(`${process.env.VUE_APP_WEBSITE_URL}/solar`)
      } else {
        this.$router.push("/")
      }
    },
    goToSuccessPage() {
      this.$router.push({
        name: Page.ThankYou,
        query: { covered: "true" },
        params: {
          email: this.$store.state.quote.email,
        },
      })
      this.$store.commit("RESET_STATE")
    },
  },
})
</script>

<style lang="scss" scoped>
.quote-container {
  background-color: $grey-50;
  flex: 1;
  &__steps {
    padding-top: 0;

    @include sm {
      padding: 0 $space-4 $space-6 $space-4;
    }

    @include md {
      padding: 0 0 $space-6 0;
      margin: 0 auto;
      width: $width-4;
    }
  }

  &__steps.full-width {
    @include md {
      width: fit-content;
      max-width: calc(100% - (#{$space-6} * 2));
    }
  }
}
</style>
