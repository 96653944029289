<template>
  <div v-if="pageContent" class="new-address">
    <h1 class="new-address__title h4">
      {{ pageContent.postcode }}
    </h1>
    <QuoteInput variant="primary" @postcode-is-valid="handleValidPostcode" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import QuoteInput from "@soenergy/frontend-library/src/components/QuoteInput.vue"
import { mapMutations } from "vuex"
import serveUserBasedOnPostcode from "@/helpers/serveUserBasedOnPostcode"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/new-postcode"

interface SolarQuoteNewPostcode {
  postcode: string
}

export default defineComponent({
  components: { QuoteInput },
  mixins: [cmsPreviewMixin<SolarQuoteNewPostcode>({ story })],
  emits: ["go-forward"],
  data() {
    return {
      postcodeChange: false,
    }
  },
  methods: {
    ...mapMutations({
      setPostcode: "SET_POSTCODE",
    }),
    handleValidPostcode({ customerPostcode }) {
      this.setPostcode(customerPostcode)
      this.postcodeChange = true
      serveUserBasedOnPostcode(customerPostcode)
    },
  },
})
</script>

<style lang="scss" scoped>
.new-address {
  &__title {
    text-transform: none;
  }
}

.quote-container {
  padding-bottom: $spacing-10;
}
</style>
