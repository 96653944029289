<template>
  <section v-if="pageContent" class="find-roof">
    <Card two-column-layout>
      <template #heading>
        <div class="find-roof__address">
          <div v-if="showAddressForm">
            <div class="find-roof__heading">
              <p class="find-roof__heading-text">
                {{ pageContent.changeAddressFormTitle }}
              </p>
              <a
                href="javascript:;"
                class="find-roof__heading-btn"
                @click="cancelAddressChange"
              >
                {{ pageContent.changeAddressCancelButtonCopy }}
              </a>
            </div>
            <div class="find-roof__address-form">
              <InputField
                v-model="addressForm.address"
                label="First line of address"
                name="address"
                class="full-width"
              />

              <div class="columns is-mobile">
                <div class="column">
                  <InputField
                    v-model="addressForm.postcode"
                    name="postcode"
                    label="Postcode"
                  />
                </div>
                <div class="column is-narrow">
                  <AppButton
                    class="find-roof__address-search"
                    :loading="isSearchingAddress"
                    :disabled="!addressFormModified"
                    @click="searchAddress"
                  >
                    {{ pageContent.changeAddressSearchButtonCopy }}
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="find-roof__heading">
            <img :src="require('@/assets/images/location.svg')" class="mr-2" />
            <p class="find-roof__heading-text">
              {{ getFullAddress }}
            </p>
            <a
              href="javascript:;"
              class="find-roof__heading-btn"
              @click="toggleAddressForm"
            >
              {{ pageContent.changeAddressButtonCopy }}
            </a>
          </div>
        </div>
        <div id="address-tooltip-ref" class="find-roof__tooltip-ref"></div>
        <Tooltip
          v-if="addressTooltip === TOOLTIP.SKIP_STEP"
          :tooltip-content="pageContent.addressNotFoundTooltipText2"
          :got-it-button="pageContent.addressNotFoundTooltipBtn2"
          class="find-roof__tooltip"
          reference="#address-tooltip-ref"
          boundary="#address-tooltip-ref"
          @close="skipThisStep"
        />

        <Tooltip
          v-if="addressTooltip === TOOLTIP.TRY_AGAIN"
          :tooltip-content="pageContent.addressNotFoundTooltipText1"
          :got-it-button="pageContent.addressNotFoundTooltipBtn1"
          class="find-roof__tooltip"
          reference="#address-tooltip-ref"
          boundary="#address-tooltip-ref"
          @close="toggleAddressForm"
        />
      </template>

      <div class="find-roof__content">
        <h1 class="find-roof__title h4">
          {{ pageContent.title }}
        </h1>

        <ul class="find-roof__reqs">
          <li
            ref="req-zoom"
            class="find-roof__reqs-req"
            :class="{ 'find-roof__reqs-req--pass': isMapZoomed }"
          >
            {{ pageContent.zoomRequirementText }}
          </li>
          <li
            ref="req-map-centered"
            class="find-roof__reqs-req"
            :class="{ 'find-roof__reqs-req--pass': isMapCentered }"
          >
            {{ pageContent.mapMoveRequirementText }}
          </li>
          <li
            ref="req-marker-set"
            class="find-roof__reqs-req"
            :class="{ 'find-roof__reqs-req--pass': isMarkerSet }"
          >
            {{ pageContent.markerRequirementText }}
          </li>
        </ul>

        <div class="find-roof__buttons">
          <AppButton
            class="find-roof__buttons-next"
            :disabled="!canGoToNextStep"
            @click="goToNextStep"
          >
            <span>{{ pageContent.nextStepButtonCopy }}</span>
          </AppButton>

          <a
            href="javascript:void(0)"
            class="find-roof__buttons-noroof"
            @click.prevent="showCantFindRoofModal = true"
          >
            {{ pageContent.cantFindRoofButtonCopy }}
          </a>
        </div>
      </div>

      <template #rightColumn>
        <div class="find-roof__map">
          <GMap
            :location="markerLocation"
            :address="getFullAddress"
            class="find-roof__map-inner"
            @marker-change="onMapMarkerChange"
            @zoom-change="onMapZoomChange"
            @map-drag="onMapDrag"
            @error="onMapError"
            @address-search="onAddressSearch"
          >
            <template #mapError>
              <AppButton class="mt-5" @click="skipThisStep">
                {{ pageContent.addressNotFoundTooltipBtn2 }}
              </AppButton>
            </template>
          </GMap>
        </div>
      </template>
    </Card>
    <Modal
      class="find-roof__modal"
      :is-modal-open="showCantFindRoofModal"
      @close-modal="toggleCantFindRoofModal"
    >
      <template #header>
        <h4 class="h4">{{ pageContent.cantFindRoofModalTitle }}</h4>
      </template>
      <template #body>
        <Article :text-content="pageContent.cantFindRoofModalContent" />
      </template>
      <template #cancelButton>
        {{ pageContent.cantFindModalCloseButtonCopy }}
      </template>
      <template #footer>
        <AppButton
          class="modal__close-button"
          variant="invertedPrimary"
          @click="showCantFindRoofModal = false"
        >
          {{ pageContent.cantFindModalCloseButtonCopy }}
        </AppButton>

        <AppButton class="modal__close-button" @click="skipThisStep">
          {{ pageContent.cantFindModalConfirmButtonCopy }}
        </AppButton>
      </template>
    </Modal>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { mapMutations, mapGetters } from "vuex"

import toTitleCase from "@/helpers/toTitleCase"

import Card from "@/components/Card.vue"
import GMap from "@/components/GMap.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import Modal from "@soenergy/frontend-library/src/components/Modal.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import Tooltip from "@soenergy/frontend-library/src/components/Tooltip.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/find-your-roof"
import { MapLocation } from "@/store/untested/types"

export interface SolarQuoteFindYourRoof {
  title: string
  minimalZoomRequired: number
  zoomRequirementText: string
  mapMoveRequirementText: string
  markerRequirementText: string
  changeAddressButtonCopy: string
  changeAddressFormTitle: string
  changeAddressCancelButtonCopy: string
  changeAddressSearchButtonCopy: string
  nextStepButtonCopy: string
  cantFindRoofButtonCopy: string
  cantFindRoofModalTitle: string
  cantFindRoofModalContent: string
  cantFindModalCloseButtonCopy: string
  cantFindModalConfirmButtonCopy: string
  addressNotFoundTooltipText1: string
  addressNotFoundTooltipBtn1: string
  addressNotFoundTooltipText2: string
  addressNotFoundTooltipBtn2: string
}

export interface Address {
  address: string
  postcode: string
}

const TOOLTIP = {
  TRY_AGAIN: 1,
  SKIP_STEP: 2,
}

export default defineComponent({
  components: {
    AppButton,
    InputField,
    Modal,
    Article,
    Card,
    GMap,
    Tooltip,
  },
  mixins: [cmsPreviewMixin<SolarQuoteFindYourRoof>({ story })],
  emits: ["go-forward"],
  data: () => ({
    TOOLTIP: TOOLTIP,
    showAddressForm: false,
    markerLocation: null as MapLocation | null,
    mapZoom: null,
    isMapMoved: false,
    showCantFindRoofModal: false,
    stepRevisited: false,
    isSearchingAddress: false,
    addressSearchCount: 1,
    addressTooltip: null as number | null,
    addressForm: {} as Address,
    customerAddress: {} as Address,
  }),
  computed: {
    ...mapGetters(["roofLocation", "address"]),
    getFullAddress() {
      return `${this.customerAddress.address} ${this.customerAddress.postcode}`
    },
    isMapZoomed() {
      if (this.stepRevisited) return true
      if (this.pageContent.minimalZoomRequired) {
        return (
          this.mapZoom &&
          this.mapZoom >= Number(this.pageContent.minimalZoomRequired)
        )
      }
      return true
    },
    isMapCentered() {
      return (this.isMapMoved || this.stepRevisited) && this.isMapZoomed
    },
    isMarkerSet() {
      return this.markerLocation !== null
    },
    canGoToNextStep() {
      return this.isMarkerSet && this.isMapZoomed && this.isMapCentered
    },
    addressFormModified() {
      return (
        this.addressForm.postcode !== this.customerAddress.postcode ||
        this.addressForm.address !== this.customerAddress.address
      )
    },
  },
  created() {
    this.resetAddressForm()
    if (this.roofLocation) {
      this.markerLocation = this.roofLocation
      this.stepRevisited = true
    }
  },
  methods: {
    ...mapMutations({
      setRoofLocation: "SET_ROOF_LOCATION",
      setAddressLines: "SET_ADDRESS_LINES",
      setPostcode: "SET_POSTCODE",
    }),
    resetAddressForm() {
      let address = toTitleCase(this.address)
      let postcode = this.$store.state.quote.postcode as string
      postcode = postcode.toUpperCase()
      this.customerAddress = { address, postcode }
      this.addressForm = { ...this.customerAddress }
    },
    searchAddress() {
      this.isSearchingAddress = true
      this.setRoofLocation(null)
      this.markerLocation = null
      this.isMapMoved = false
      this.customerAddress = { ...this.addressForm }
      this.addressSearchCount++
    },
    toggleAddressForm() {
      this.showAddressForm = !this.showAddressForm
      this.addressTooltip = null
    },
    toggleCantFindRoofModal() {
      this.showCantFindRoofModal = !this.showCantFindRoofModal
    },
    skipThisStep() {
      this.setRoofLocation(null)
      this.markerLocation = null
      this.showCantFindRoofModal = false
      this.$emit("go-forward")
    },
    onMapMarkerChange(location) {
      var markerLocation = null as MapLocation | null
      if (location) {
        markerLocation = {
          lat: location.lat(),
          lng: location.lng(),
        }
      }
      this.markerLocation = markerLocation
    },
    onMapZoomChange(zoom) {
      this.mapZoom = zoom
    },
    onMapDrag() {
      this.isMapMoved = true
    },
    onAddressSearch() {
      this.isSearchingAddress = false
      this.addressTooltip = null
    },
    onMapError(error) {
      this.isSearchingAddress = false
      if (error === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        this.addressTooltip =
          this.addressSearchCount > 1 ? TOOLTIP.SKIP_STEP : TOOLTIP.TRY_AGAIN
      } else {
        this.addressTooltip = TOOLTIP.SKIP_STEP
      }
    },
    cancelAddressChange() {
      this.isSearchingAddress = false
      this.showAddressForm = false
      this.markerLocation = null
      this.isMapMoved = false
      this.resetAddressForm()
    },
    goToNextStep() {
      this.setPostcode(this.customerAddress.postcode)
      this.setAddressLines(this.customerAddress.address.toUpperCase())
      this.setRoofLocation(this.markerLocation)
      this.$emit("go-forward")
    },
  },
})
</script>

<style lang="scss">
.find-roof {
  width: $width-10;
  max-width: 100%;
  &__title.h4 {
    text-transform: none;
    margin-top: $space-8;
  }
  &__heading {
    display: flex;
    align-items: center;
    &-text {
      white-space: nowrap;
      margin-right: $space-2;
      font-weight: $weight-medium;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-btn {
      margin-left: auto;
      white-space: nowrap;
      flex-shrink: 0;
    }
  }
  &__tooltip {
    display: block;
    width: 100%;
    height: 1px;
    &-ref {
      width: $space-8;
    }
    .tooltip {
      max-width: 360px;
    }
  }
  &__map {
    display: flex;
    align-content: stretch;
    background: $grey-400;
    margin: $space-8 (-$space-4) 0 (-$space-4);
    overflow: hidden;

    // map container to be square
    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    &-inner {
      width: 100%;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__address {
    &-form {
      margin-top: $space-4;
      max-width: $width-4;
    }
    &-row {
      display: flex;
      align-items: stretch;
    }
    &-search {
      min-width: 135px;
      margin-top: $space-1;
      height: 72px; // match input height;
    }
  }
  &__reqs {
    margin-bottom: $space-9;
    &-req:not(:last-child) {
      margin-bottom: $space-4;
    }
    &-req {
      padding-left: $space-9;
      background-position: $space-2 $space-2;
      background-size: auto $space-3;
      background-repeat: no-repeat;
      background-image: url("@/assets/images/status-fail.svg");
    }
    &-req--pass {
      background-image: url("@/assets/images/status-pass.svg");
    }
  }

  @include md {
    font-size: $font-size-4;
    &__address,
    &__content,
    &__tooltip .tooltip {
      max-width: 360px;
      padding: 0;
    }
    &__map {
      margin: 0;
      border-radius: $space-2;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    &__buttons {
      display: block;
      &-noroof {
        font-size: $font-size-4;
        margin-top: $space-8;
        display: block;
      }
    }
  }
}
</style>
